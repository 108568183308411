import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { ClickedPhaseData, ExportArchiveAccordionProps } from './FullExportAccordion.types'
import {
  StyledFullExportAccordion,
  StyledFullExportAccordionDetails,
  StyledFullExportAccordionSummary
} from './FullExportAccordion.styles'
import FieldForm from '../../../../../../components/FieldForm'
import { Stack, Typography } from '@mui/material'
import { FullExportInnerAccordion } from '../FullExportInnerAccordion'
import { useFormikContext } from 'formik'
import { ProjectFullExportFormData } from '../ProjectFullExportForm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const FullExportAccordion: FC<ExportArchiveAccordionProps> = ({
  phase,
  phaseIndex,
  accordionHideByFilter
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFullExportFormData>()
  const { phaseList } = formValues

  const [expanded, setExpanded] = useState<boolean>(false)

  const accordionFieldName = `phaseList[${phaseIndex}].selected`
  const accordionFieldChecked = phaseList[phaseIndex].selected
  const setOpenedManually = () => {
    setFieldValue(`phaseList[${phaseIndex}].openedManually`, !expanded)
  }

  const onSummaryClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setExpanded(prevState => !prevState)
    setOpenedManually()
  }

  // set object checkbox checked when all inner groups checked
  useEffect(() => {
    if (phase.type === 'РД') {
      const filteredObjects = phaseList[phaseIndex]?.objects?.filter(object => !object.hideByFilter)

      if (filteredObjects?.length && filteredObjects.every(object => object.selected)) {
        setFieldValue(`phaseList[${phaseIndex}].selected`, true)
      }
    } else {
      const filteredGroups = phaseList[phaseIndex]?.groups?.filter(group => !group.hideByFilter)

      if (filteredGroups?.length && filteredGroups.every(group => group.selected)) {
        setFieldValue(`phaseList[${phaseIndex}].selected`, true)
      }
    }
  }, [phaseList[phaseIndex]?.objects, phaseList[phaseIndex]?.groups])

  // close and open group accordion depending on checked status
  useEffect(() => {
    if (phaseList[phaseIndex]?.selected) {
      setExpanded(false)
      return
    }

    if (phaseList[phaseIndex]?.selected !== undefined && phaseList[phaseIndex]?.openedManually) {
      setExpanded(true)
    }
  }, [phaseList[phaseIndex]?.selected])

  const [clickedPhaseData, setClickedPhaseData] = useState<ClickedPhaseData | null>(null)

  const onPhaseChange = (checked: boolean) => {
    setClickedPhaseData({ phase: phase.type, checked })
  }

  const clearClickedPhaseData = () => {
    setClickedPhaseData(null)
  }

  if (accordionHideByFilter) return null

  return (
    <StyledFullExportAccordion
      expanded={expanded}
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
    >
      <StyledFullExportAccordionSummary
        onClick={(e) => onSummaryClick(e)}
        expandIcon={(
          <ExpandMoreIcon
            fontSize='medium'
            color='primary'
          />
        )}
      >
        <FieldForm
          version='checkbox'
          name={accordionFieldName}
          checkboxData={{
            label: (
              <Stack direction='row'>
                <Typography minWidth={36} variant='subtitle2'>{phase.type}</Typography>
                <Typography variant='subtitle2'>{phase.name}</Typography>
              </Stack>
            ),
            checked: accordionFieldChecked,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              const checked = e.target.checked
              onPhaseChange(checked)
              setFieldValue(`phaseList[${phaseIndex}].selected`, checked)
            },
            onLabelClick: (e) => e.stopPropagation(),
          }}
        />
      </StyledFullExportAccordionSummary>

      <StyledFullExportAccordionDetails>
        {phase.type === 'РД'
          ? phase.objects?.map((object, index) => (
            <FullExportInnerAccordion
              phase='РД'
              phaseIndex={phaseIndex}
              object={object}
              objectIndex={index}

              clickedPhaseData={clickedPhaseData}
              clearClickedPhaseData={clearClickedPhaseData}
              key={`${phase.type}-object-${object.name}`}
            />
          ))
          : phase.groups?.map((group, index) => (
            <FullExportInnerAccordion
              phase={phase.type as 'ПД' | 'ИИ' | 'ИРД'}
              phaseIndex={phaseIndex}
              group={group}
              groupIndex={index}

              clickedPhaseData={clickedPhaseData}
              clearClickedPhaseData={clearClickedPhaseData}
              key={`${phase.type}-group-${group.name}`}
            />
          ))
        }
      </StyledFullExportAccordionDetails>
    </StyledFullExportAccordion>
  )
}
