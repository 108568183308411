import { LoadingWrapper, ScrollableSideBarContainer, SettingBlockStyledSwitch, SettingsBlock, SideBarWrapper, StampButton, StyledToggleButton, StyledToggleButtonGroup } from "./StampRightSideBar.styles"
import { format as FnsFormat } from 'date-fns'
import { useAppDispatch, useTypedSelector } from "@store/store"
import { setAutomaticPlacement, setIsSelectedQRCode, setSelectedStamp, setStampSettings } from "@store/slices/pdfViewer/pdfViewer"
import { StampTypes } from "@store/slices/pdfViewer/pdfViewer.types"
import { automaticPlacementSelector, isLoadingPageSizesSelector, isSelectedQRCodeSelector, qrCodesSelector, selectedStampSelector, stampModeSelector, stampSettingsSelector, stampsSelector } from "@store/slices/pdfViewer/selectors/pdfViewer.selectors"
import Button from "@components/Button"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Stack, Typography } from "@mui/material"
import { StampRightSideBarProps } from "./StampRightSideBar.types"
import { QRCodeGenerate } from "../QRCodeGenerate"
import { useEffect, useMemo, useState } from "react"
import { config } from "@core/config"
import { projectPhaseRuShortToEnShort } from "@typesGlobal/project"
import { CustomStampForm } from "../CustomStampForm"
import { useCreateStamp } from "../PragmaPdfViewer/components/StampHandler/useCreateStamp"
import { profileSelector } from "@store/slices/profile"
import { useSnackbar } from "notistack"
import { AutomaticStampPlacement } from "../AutomaticStampPlacement"
import Progress from "@components/Progress"

const currentDate = FnsFormat(new Date(), 'dd.MM.yyyy')

export const StampRightSideBar = ({
  docId,
  type,
  handleCancel,
  handleSave,
  handleChangeStampMode
}: StampRightSideBarProps) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const selectedStamp = useTypedSelector(selectedStampSelector)
  const qrCodes = useTypedSelector(qrCodesSelector)
  const stamps = useTypedSelector(stampsSelector)
  const stampMode = useTypedSelector(stampModeSelector)
  const selectedQrCode = useTypedSelector(isSelectedQRCodeSelector)
  const stampSettings = useTypedSelector(stampSettingsSelector)
  const automaticPlacement = useTypedSelector(automaticPlacementSelector)
  const isLoadingPageSizes = useTypedSelector(isLoadingPageSizesSelector)

  const { company, firstName, lastName, middleName } = useTypedSelector(profileSelector)

  const [stampChangeMode, setStampChangeMode] = useState(false) // При false режим по умлоч
  const [isValidFields, setIsValidFields] = useState(true)

  const link = useMemo(() => `https://${config.appHost}/qr/${docId}?phase=${projectPhaseRuShortToEnShort[type]}`, [docId, type])

  const { stampsData } = useCreateStamp({ stampSettings })
  const filterApplied = stampsData.length === 1

  const setDefaultStampData = (type: StampTypes) => {
    const firstNameInitial = firstName ? firstName[0] : ''
    const middleNameInitial = middleName ? middleName[0] + '.' : ''
    let filteredStapmSettings = stampSettings.filter(stamp => stamp.type === type)[0]
    filteredStapmSettings = {
      company: company.userCompanyName,
      companyVisibility: true,
      date: currentDate,
      name: `${lastName} ${firstNameInitial}. ${middleNameInitial}`.trim(),
      nameVisibility: true,
      position: company.userPosition,
      positionVisibility: true,
      signature: true,
      title: filteredStapmSettings.sourceTitle,
      type: filteredStapmSettings.type,
      sourceTitle: filteredStapmSettings.sourceTitle
    }
    dispatch(setStampSettings([filteredStapmSettings]))
  }

  const handleStampClick = (type: StampTypes) => {
    if (filterApplied) {
      if (isValidFields) {
        dispatch(setSelectedStamp({ isActive: true, type }))
      } else {
        enqueueSnackbar('Заполните обязательные поля', { variant: 'error' })
      }
    } else {
      setDefaultStampData(type)
    }
  }

  const handleQRCodeClick = () => {
    dispatch(setIsSelectedQRCode(true))
  }

  const onCancelClick = () => {
    //setStampChangeMode(false)
    handleCancel({})
  }

  const onChangeStampMode = (value: ('stamp' | 'qrcode')) => {
    //setStampChangeMode(false)
    handleChangeStampMode(value)
  }

  const handleStampChangeMode = (value: boolean) => {
    if (!value) {
      setDefaultStampData(stampSettings[0].type)
      setIsValidFields(true)
    }
    setStampChangeMode(value)
  }

  // Очистка состояния isActive уже выбранного штампа, если валидация не прошла
  const onValidationChange = (isValid: boolean) => {
    if (!isValid && selectedStamp?.type) {
      dispatch(setSelectedStamp({ type: selectedStamp.type, isActive: false }))
    }
    setIsValidFields(isValid)
  }

  const setAutomaticStampPlacement = (value: boolean) => {
    dispatch(setAutomaticPlacement(value))
  }

  useEffect(() => {
    if (stampSettings.length > 0) {
      setStampChangeMode(false)
      setIsValidFields(true)
      dispatch(setAutomaticPlacement(false)) // Выключение автоматической простановки 
    }
  }, [stampSettings.length])

  return (
    <SideBarWrapper direction='column'>
      <Button icon color='primary' sx={{ gap: 1, alignSelf: 'flex-start', mb: 1.25 }}
        variant='text' onClick={onCancelClick}>
        <ArrowBackIcon />
        Назад
      </Button>

      <StyledToggleButtonGroup
        value={stampMode}
        exclusive
        color='primary'
        onChange={(e, value) => onChangeStampMode(value)}
      >
        <StyledToggleButton value='stamp' >штампы</StyledToggleButton>
        <StyledToggleButton value='qrcode' >qr-коды</StyledToggleButton>
      </StyledToggleButtonGroup>
      <ScrollableSideBarContainer>
        {stampMode === 'stamp' ?
          <>
            <Stack direction='column' spacing={1}>
              {stampsData.map((stamp) =>
                <StampButton onClick={() => handleStampClick(stamp.type)} key={stamp.type} border={!!selectedStamp?.isActive}>
                  <img src={stamp.data} width='100%' alt={stamp.name} />
                </StampButton>
              )}

            </Stack>
            {filterApplied &&
              <Stack direction='column' spacing={2} my={2}>
                <SettingsBlock>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant="h1" fontSize={14}>Изменение штампа</Typography>
                    <SettingBlockStyledSwitch
                      checked={stampChangeMode}
                      onChange={(e) => handleStampChangeMode(e.target.checked)}
                    />
                  </Stack>
                  <CustomStampForm
                    stampChangeMode={stampChangeMode}
                    setIsValidFields={onValidationChange} />
                </SettingsBlock>
                <SettingsBlock>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant="h1" fontSize={14}>Автоматическая простановка</Typography>
                    <SettingBlockStyledSwitch
                      checked={automaticPlacement}
                      onChange={(e) => setAutomaticStampPlacement(e.target.checked)}
                    />
                  </Stack>
                  <AutomaticStampPlacement automaticPlacement={automaticPlacement} />
                </SettingsBlock>
              </Stack>
            }
          </>
          :
          <Stack>
            <StampButton onClick={handleQRCodeClick} border={selectedQrCode}>
              <QRCodeGenerate value={link} />
            </StampButton>
            <SettingsBlock>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant="h1" fontSize={14}>Автоматическая простановка</Typography>
                <SettingBlockStyledSwitch
                  checked={automaticPlacement}
                  onChange={(e) => setAutomaticStampPlacement(e.target.checked)}
                />
              </Stack>
              <AutomaticStampPlacement automaticPlacement={automaticPlacement} />
            </SettingsBlock>
          </Stack>
        }
      </ScrollableSideBarContainer>

      {(filterApplied || stampMode === 'qrcode') &&
        <Box display='inline-flex' mr={1.25}>
          <Button fullWidth color='success'
            disabled={stampMode === 'stamp' ? stamps.length === 0 : qrCodes.length === 0}
            sx={{ mt: 'auto', mr: '10px' }} onClick={handleSave}
          >Сохранить
          </Button>
        </Box>
      }

      <LoadingWrapper open={isLoadingPageSizes && automaticPlacement}>
        <Stack gap={2}>
          <Typography>Подготовка документа...</Typography>
          <Progress />
        </Stack>
      </LoadingWrapper>
    </SideBarWrapper>
  )
}