import React, { FC, useEffect, useState } from 'react'
import {
  FormPocketDrawerFilter,
  FormPocketDrawerMainListWrapper,
  FormPocketDrawerPhaseButton,
  FormPocketDrawerPhaseGroup,
  FormPocketDrawerSearch
} from './FormPocketDrawerMainList.styles'
import { Divider, InputAdornment, Stack, Typography } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { SelectItem } from '../../../../../../components/FilterSelectMulti/FilterSelectMultiple.types'
import { tomStatusesArray } from '../../../../../../types/tom'
import { ScrollableContainer } from '../../../../../../styles/global/ScrollableContainer'
import { ProjectExportGroup, ProjectExportTomStatus } from '../../../../../../api/projects/types'
import { useFormikContext } from 'formik'
import { FormPocketDrawerFormData } from '../FormPocketDrawerForm'
import { FormPocketInnerAccordion } from '../FormPocketInnerAccordion'
import { StyledSearchIcon } from '../../../../../../components/Header/Search/styles'
import { FormPocketDrawerMainListProps } from './FormPocketDrawerMainList.types'

export const FormPocketDrawerMainList: FC<FormPocketDrawerMainListProps> = ({
  selectedPhase,
  onPhaseChange,
  showPhaseSwitcher,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<FormPocketDrawerFormData>()
  const { objectList, groupList } = formValues

  const [selectedTomStatuses, setSelectedTomStatuses] = useState<ProjectExportTomStatus[]>([])
  const [tomSearchValue, setTomSearchValue] = useState<string>('')


  const statusSelectItems: SelectItem<string>[] = ['В производстве', ...tomStatusesArray]
    .filter(status => {
      if (status === 'В производстве') return selectedPhase === 'РД'
      if (status === 'Не согласуется') return false
      return true
    })
    .map(status => ({
      value: status,
      title: status,
    }))

  const filterGroups = (groups: ProjectExportGroup[], statuses: ProjectExportTomStatus[], searchName: string) => {
    const updatedGroups = groups.map<ProjectExportGroup>(group => {

      const updatedToms = group.toms.map(tom => ({
        ...tom,
        hideByFilter: (!!statuses.length && !statuses.includes(tom.status))
          || !tom.name.toLocaleLowerCase().includes(searchName),
      }))
      const hideRd = updatedToms.every(tom => tom.hideByFilter)
      return {
        ...group,
        toms: updatedToms,
        hideByFilter: hideRd,
      }

    })

    return updatedGroups
  }

  const filterData = (statuses: ProjectExportTomStatus[], searchName: string) => {
    if (selectedPhase === 'РД') {
      if (!objectList || objectList.length === 0) return
      const updatedObjects = objectList.map(object => {
        const updatedGroups = filterGroups(object.groups, statuses, searchName)
        const hideObject = updatedGroups.every(group => group.hideByFilter)

        return {
          ...object,
          groups: updatedGroups,
          hideByFilter: hideObject,
        }
      })

      setFieldValue('objectList', updatedObjects)
      return
    }
    if (!groupList || groupList.length === 0) return
    const updatedGroups = filterGroups(groupList, statuses, searchName)
    setFieldValue('groupList', updatedGroups)
  }

  const onFilter = (statuses: ProjectExportTomStatus[], searchName: string) => {
    statuses.length !== selectedTomStatuses.length && setSelectedTomStatuses(statuses)
    searchName !== tomSearchValue && setTomSearchValue(searchName)
  }

  useEffect(() => {
    filterData(selectedTomStatuses, tomSearchValue)
  }, [selectedTomStatuses, tomSearchValue])

  return (
    <FormPocketDrawerMainListWrapper>
      <Stack spacing={1.5}>
        {showPhaseSwitcher && (
          <FormPocketDrawerPhaseGroup
            value={selectedPhase}
            exclusive
            onChange={(e, newValue) => onPhaseChange(newValue)}
          >
            <FormPocketDrawerPhaseButton value='ИИ'>Изыскания</FormPocketDrawerPhaseButton>
            <Divider flexItem orientation='vertical' />

            <FormPocketDrawerPhaseButton value='ПД'>ПД</FormPocketDrawerPhaseButton>
            <Divider flexItem orientation='vertical' />

            <FormPocketDrawerPhaseButton value='РД'>РД</FormPocketDrawerPhaseButton>
          </FormPocketDrawerPhaseGroup>
        )}

        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3} pr={2.5} pb={2}>
          <FormPocketDrawerFilter
            items={statusSelectItems}
            value={selectedTomStatuses}
            onChange={(value: any) => onFilter(value as ProjectExportTomStatus[], tomSearchValue)}
            defaultValue='Статус тома'
            allSelectedText='Все типы'
            paperFullHeight
            startIcon={<FilterAltIcon fontSize='large' color='secondary' />}
          />

          <FormPocketDrawerSearch
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <StyledSearchIcon color='disabled' />
                </InputAdornment>
              ),
            }}
            placeholder='Шифр тома'
            onChange={(e) => onFilter(selectedTomStatuses, e.target.value.toLocaleLowerCase())}
          />
        </Stack>
      </Stack>

      <ScrollableContainer spacing={1.5}>
        {selectedPhase === 'РД'
          ? objectList && objectList.length > 0
            ? objectList.map((object, index) => (
              <FormPocketInnerAccordion
                phase='РД'
                object={object}
                objectIndex={index}
                key={`${selectedPhase}-object-${object.name}`}
              />
            )) : (
              <Stack margin='auto'>
                <Typography variant="h3" fontSize={16} fontWeight={500}>Нет доступных для выбора документов.</Typography>
              </Stack>
            )
          : groupList && groupList.length > 0
            ? groupList.map((group, index) => (
              <FormPocketInnerAccordion
                phase={selectedPhase}
                group={group}
                groupIndex={index}
                key={`${selectedPhase}-group-${group.name}`}
              />
            )) : (
              <Stack margin='auto'>
                <Typography variant="h3" fontSize={16} fontWeight={500}>Нет доступных для выбора документов.</Typography>
              </Stack>
            )
        }
      </ScrollableContainer>
    </FormPocketDrawerMainListWrapper>
  )
}
