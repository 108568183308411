import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PdfVieweState, QRCode, SelectedStamp, Stamp, StampMode, StampSettings, StampTypes } from "./pdfViewer.types";
import { format as FnsFormat } from 'date-fns'

const currentDate = FnsFormat(new Date(), 'dd.MM.yyyy')

const defaultStampSettings: StampSettings[] = [
  {
    title: 'Согласовано',
    date: currentDate,
    name: 'Фамилия И.О.',
    nameVisibility: true,
    position: 'Должность',
    positionVisibility: true,
    company: 'Компания',
    companyVisibility: true,
    signature: true,
    type: 'agreed',
    sourceTitle: 'Согласовано'
  },
  {
    title: 'Утверждено',
    date: currentDate,
    name: 'Фамилия И.О.',
    nameVisibility: true,
    position: 'Должность',
    positionVisibility: true,
    company: 'Компания',
    companyVisibility: true,
    signature: true,
    type: 'approved',
    sourceTitle: 'Утверждено'
  },
  {
    title: 'В производство работ',
    date: currentDate,
    name: 'Фамилия И.О.',
    nameVisibility: true,
    position: 'Должность',
    positionVisibility: true,
    company: 'Компания',
    companyVisibility: true,
    signature: true,
    type: "inProd",
    sourceTitle: 'В производство работ'
  }
]

let initialState: PdfVieweState = {
  stampSettings: defaultStampSettings,
  selectedStamp: null,
  stamps: [],
  saveTrigger: false,
  stampMode: 'stamp',
  isSelectedQRCode: false,
  qrCodeData: null,
  qrCodes: [],
  documentPages: null,
  pagesForStamp: [],
  automaticPlacement: false,
  isSubmitPlacement: false,
  isHighlighted: false,
  isLoadingPageSizes: false,
  selectAllPages: true
}

const pdfViewersSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setSelectedStamp: (state, { payload }: PayloadAction<SelectedStamp | null>) => {
      if (payload === null) {
        state.stampSettings = defaultStampSettings
      }
      state.selectedStamp = payload
    },
    setStampSettings: (state, { payload }: PayloadAction<StampSettings[]>) => {
      const { title, company, companyVisibility, date, name, nameVisibility, position, positionVisibility, signature, type, sourceTitle } = payload[0]
      state.stampSettings = [
        {
          title,
          company,
          companyVisibility,
          date,
          name,
          nameVisibility,
          position,
          positionVisibility,
          signature,
          type, //Предыдущий тип, пока без кастом
          sourceTitle,
        }
      ]
    },
    setStamps: (state, { payload }: PayloadAction<Stamp[]>) => {
      state.stamps = payload
    },
    setSaveTrigger: (state, { payload }: PayloadAction<boolean>) => {
      state.saveTrigger = payload
    },
    setStampMode: (state, { payload }: PayloadAction<StampMode>) => {
      state.stampMode = payload
    },
    setIsSelectedQRCode: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectedQRCode = payload
    },
    setQrCodeData: (state, { payload }: PayloadAction<string | null>) => {
      state.qrCodeData = payload
    },
    setQRCodes: (state, { payload }: PayloadAction<QRCode[]>) => {
      state.qrCodes = payload
    },
    setDocumentPages: (state, { payload }: PayloadAction<number | null>) => {
      state.documentPages = payload
    },
    setPagesForStamp: (state, { payload }: PayloadAction<number[]>) => {
      state.pagesForStamp = payload
    },
    setAutomaticPlacement: (state, { payload }: PayloadAction<boolean>) => {
      state.automaticPlacement = payload
      if (payload && state.documentPages) {
        state.pagesForStamp = Array.from({ length: state.documentPages }, (_, i) => i + 1)
      } else {
        state.selectAllPages = true
        state.pagesForStamp = []
      }
    },
    setIsSubmitPlacement: (state, { payload }: PayloadAction<boolean>) => {
      state.isSubmitPlacement = payload
    },
    setIsHighlighted: (state, { payload }: PayloadAction<boolean>) => {
      state.isHighlighted = payload
    },
    setIsLoadingPageSizes: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingPageSizes = payload
    },
    setSelectAllPages: (state, { payload }: PayloadAction<boolean>) => {
      state.selectAllPages = payload
    },
  },
})

export const { reducer: pdfViewerReducer } = pdfViewersSlice
export const {
  setSelectedStamp,
  setStampSettings,
  setStamps,
  setSaveTrigger,
  setStampMode,
  setIsSelectedQRCode,
  setQrCodeData,
  setQRCodes,
  setDocumentPages,
  setPagesForStamp,
  setAutomaticPlacement,
  setIsSubmitPlacement,
  setIsHighlighted,
  setIsLoadingPageSizes,
  setSelectAllPages
} = pdfViewersSlice.actions
