import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { FullExportInnerAccordionProps } from './FullExportInnerAccordion.types'
import {
  AccordionSubCheckbox,
  StyledFullExportAccordion,
  StyledFullExportAccordionDetails,
} from '../FullExportAccordion'
import FieldForm from '../../../../../../components/FieldForm'
import { Stack, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { ProjectFullExportFormData } from '../ProjectFullExportForm'
import { theme } from '../../../../../../styles/theme'
import { ProjectFullExportTomItem } from '../ProjectFullExportTomItem'
import { StyledFullExportInnerAccordionSummary } from './FullExportInnerAccordion.styles'
import {
  GetUpdatedSelectedTomsArgs,
  SetCheckedRdGroupArgs,
  useAccordionCheckState
} from '../FullExportAccordion/hooks/useAccordionCheckState'
import { ProjectExportGroup, ProjectExportObject } from '../../../../../../api/projects/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { OverflowTextNew } from '@components/OverflowTextNew'

export const FullExportInnerAccordion: FC<FullExportInnerAccordionProps> = ({
  phase,
  phaseIndex,
  object,
  objectIndex,
  group,
  groupIndex,

  clickedPhaseData,
  clearClickedPhaseData,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFullExportFormData>()
  const { phaseList } = formValues

  const [expanded, setExpanded] = useState<boolean>(false)

  const accordionLabel = (phase === 'РД' ? object : group).name
  const accordionFieldName = phase === 'РД'
    ? `phaseList[${phaseIndex}].objects[${objectIndex}].selected`
    : `phaseList[${phaseIndex}].groups[${groupIndex}].selected`

  const accordionFieldChecked = phase === 'РД'
    ? phaseList[phaseIndex]?.objects![objectIndex]?.selected
    : phaseList[phaseIndex]?.groups![groupIndex]?.selected

  const setOpenedManually = () => {
    phase === 'РД'
      ? setFieldValue(`phaseList[${phaseIndex}].objects[${objectIndex}].openedManually`, !expanded)
      : setFieldValue(`phaseList[${phaseIndex}].groups[${groupIndex}].openedManually`, !expanded)
  }

  const onSummaryClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setExpanded(prevState => !prevState)
    setOpenedManually()
  }

  // set object accordion checkbox checked when all rd groups checked
  useEffect(() => {
    if (phase !== 'РД') return

    const filteredGroups = phaseList[phaseIndex]?.objects![objectIndex].groups?.filter(group => !group.hideByFilter)

    if (filteredGroups?.length && filteredGroups.every(group => group.selected)) {
      setFieldValue(`phaseList[${phaseIndex}].objects[${objectIndex}].selected`, true)
    }
  }, [phase === 'РД' && phaseList[phaseIndex]?.objects![objectIndex].groups])

  // close and open this accordion depending on checked status
  useEffect(() => {
    if (phase === 'РД') {
      if (phaseList[phaseIndex]?.objects![objectIndex]?.selected) {
        setExpanded(false)
        return
      }

      if (phaseList[phaseIndex]?.objects![objectIndex]?.selected !== undefined && phaseList[phaseIndex]?.objects![objectIndex]?.openedManually) {
        setExpanded(true)
      }
    } else {
      if (phaseList[phaseIndex]?.groups![groupIndex]?.selected) {
        setExpanded(false)
        return
      }

      if (phaseList[phaseIndex]?.groups![groupIndex]?.selected !== undefined && phaseList[phaseIndex]?.groups![groupIndex]?.openedManually) {
        setExpanded(true)
      }
    }

  }, [phase === 'РД' ? phaseList[phaseIndex]?.objects![objectIndex] : phaseList[phaseIndex]?.groups![groupIndex]])

  const {
    setCheckedPhaseAccordion,
    onAccordionCheckboxChange,
    setCheckedRdGroup,
    getUpdatedSelectedToms,
    deleteTomsFromSelected,
    disableExpandIcon,
    accordionHideByFilter,
  } = useAccordionCheckState({
    phase,
    phaseIndex,
    object: object as ProjectExportObject,
    group: group as ProjectExportGroup,
    objectIndex: objectIndex as number,
    groupIndex: groupIndex as number,
  })

  useEffect(() => {
    clickedPhaseData && setCheckedPhaseAccordion({
      phase: clickedPhaseData?.phase,
      phaseIndex,
      objects: phaseList[phaseIndex]?.objects || [],
      groups: phaseList[phaseIndex]?.groups || [],
      checked: clickedPhaseData?.checked
    })

    clearClickedPhaseData()
  }, [clickedPhaseData])


  if (accordionHideByFilter) return null

  return (
    <StyledFullExportAccordion
      expanded={expanded}
      disableGutters
    >
      <StyledFullExportInnerAccordionSummary
        onClick={disableExpandIcon ? undefined : onSummaryClick}
        expandIcon={!disableExpandIcon && (
          <ExpandMoreIcon
            fontSize='medium'
            color='primary'
          />
        )}
      >
        <FieldForm
          version='checkbox'
          name={accordionFieldName}
          checkboxData={{
            label: (<OverflowTextNew variant='subtitle2'>{accordionLabel}</OverflowTextNew>),
            checked: accordionFieldChecked,
            onChange: (e: ChangeEvent<HTMLInputElement>) => onAccordionCheckboxChange(accordionFieldName, e),
            onLabelClick: (e) => e.stopPropagation(),
          }}
        />
      </StyledFullExportInnerAccordionSummary>

      <StyledFullExportAccordionDetails>
        {phase === 'РД'
          ? object.groups.map((group, innerGroupIndex) => (
              <Stack pl={2} key={`${phase}-object-${object.name}-group-${group.name}`}>
                <AccordionSubCheckbox
                  version='checkbox'
                  name={`phaseList[${phaseIndex}].objects[${objectIndex}].groups[${innerGroupIndex}].selected`}
                  checkboxData={{
                    label: (<OverflowTextNew variant='subtitle2' fontWeight={400} color={theme.palette.text.dark}>{group.name}</OverflowTextNew>),
                    checked: phaseList[phaseIndex]?.objects![objectIndex]?.groups[innerGroupIndex].selected,
                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                      const checked = e.target.checked

                      const dataForRdGroup: SetCheckedRdGroupArgs = {
                        phaseIndex,
                        group,
                        groupIndex: innerGroupIndex,
                        objectIndex,
                        checked
                      }
                      setCheckedRdGroup(dataForRdGroup)

                      const dataForToms: GetUpdatedSelectedTomsArgs = {
                        ...dataForRdGroup,
                        phase,
                      }

                      checked
                        ? setFieldValue('selectedToms', [...formValues.selectedToms, ...getUpdatedSelectedToms(dataForToms)])
                        : deleteTomsFromSelected(formValues.selectedToms, getUpdatedSelectedToms(dataForToms))

                      if (!checked) {
                        setFieldValue(`phaseList[${phaseIndex}].objects[${objectIndex}].selected`, false)
                      }
                    },
                  }}
                />

                <Stack pl={2}>
                  {group.toms?.map((tom, innerTomIndex) => {
                    if (tom.selected || tom.hideByFilter) return

                    return (
                      <ProjectFullExportTomItem
                        phase={phase}
                        tom={tom}
                        phaseIndex={phaseIndex}
                        objectIndex={objectIndex!}
                        groupIndex={innerGroupIndex}
                        tomIndex={innerTomIndex}
                        key={`${phase}-object-${object.name}-group-${group.name}-tom-${tom.id}`}
                      />
                    )
                  })}
                </Stack>
              </Stack>
            )
          )
          : (
            <Stack pl={4}>
              {group.toms?.map((tom, innerTomIndex) => {
                if (tom.selected || tom.hideByFilter) return

                return (
                  <ProjectFullExportTomItem
                    phase={phase}
                    tom={tom}
                    phaseIndex={phaseIndex}
                    groupIndex={groupIndex}
                    tomIndex={innerTomIndex}
                    key={`${phase}-group-${group.name}-tom-${tom.id}`}
                  />
                )
              })}
            </Stack>
          )
        }
      </StyledFullExportAccordionDetails>
    </StyledFullExportAccordion>
  )
}
