import { FC, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FullExportTomItemProps } from './ProjectFullExportTomItem.types'
import {
  ProjectFullExportTomItemButton,
  ProjectFullExportTomItemPhase,
  ProjectFullExportTomItemWrapper
} from './ProjectFullExportTomItem.styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { theme } from '../../../../../../styles/theme'
import { useFormikContext } from 'formik'
import { ProjectFullExportFormData, SelectedTomData } from '../ProjectFullExportForm'
import { OverflowTextNew } from '@components/OverflowTextNew'


export const ProjectFullExportTomItem: FC<FullExportTomItemProps> = ({
  phase,
  phaseIndex,
  tom,
  objectIndex,
  groupIndex,
  tomIndex,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFullExportFormData>()
  const { phaseList, selectedToms } = formValues
  const { name, status } = tom

  const currentGroup = phase === 'РД'
    ? phaseList[phaseIndex]?.objects![objectIndex]?.groups[groupIndex]
    : phaseList[phaseIndex]?.groups![groupIndex]

  const selected = !!(currentGroup?.selected || currentGroup?.toms[tomIndex]?.selected)

  // set group checkbox checked when all toms checked
  useEffect(() => {
    const filteredToms = currentGroup?.toms.filter(tom => !tom.hideByFilter)

    if (filteredToms?.length && filteredToms.every(tom => tom.selected)) {
      phase === 'РД'
        ? setFieldValue(`phaseList[${phaseIndex!}].objects[${objectIndex}].groups[${groupIndex}].selected`, true)
        : setFieldValue(`phaseList[${phaseIndex!}].groups[${groupIndex}].selected`, true)

    }
  }, [currentGroup?.toms[tomIndex]])

  const onAddClick = () => {
    phase === 'РД'
      ? setFieldValue(`phaseList[${phaseIndex!}].objects[${objectIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`, true)
      : setFieldValue(`phaseList[${phaseIndex!}].groups[${groupIndex}].toms[${tomIndex}].selected`, true)

    const selectedTomData: SelectedTomData = {
      tom,
      phase,
      phaseIndex,
      objectIndex: phase === 'РД' ? objectIndex : undefined,
      groupIndex,
      tomIndex,
    }
    setFieldValue(`selectedToms`, [...selectedToms, selectedTomData])
  }

  // Rd
  const onRemoveClick = () => {
    if (phase === 'РД') {
      setFieldValue(`phaseList[${phaseIndex!}].objects[${objectIndex}].groups[${groupIndex}].toms[${tomIndex}].selected`, false)
      setFieldValue(`phaseList[${phaseIndex!}].objects[${objectIndex}].groups[${groupIndex}].selected`, false)
      setFieldValue(`phaseList[${phaseIndex!}].objects[${objectIndex}].selected`, false)
      setFieldValue(`phaseList[${phaseIndex!}].selected`, false)
    } else {
      setFieldValue(`phaseList[${phaseIndex!}].groups[${groupIndex}].toms[${tomIndex}].selected`, false)
      setFieldValue(`phaseList[${phaseIndex!}].groups[${groupIndex}].selected`, false)
      setFieldValue(`phaseList[${phaseIndex!}].selected`, false)
    }

    const selectedTomsCopy = [...selectedToms]
    const removedTomIndex = selectedTomsCopy.findIndex(tomData => tomData.tom.id === tom.id)
    selectedTomsCopy.splice(removedTomIndex, 1)

    setFieldValue(`selectedToms`, selectedTomsCopy)
  }

  const buttonPropsBySelectedFlag = {
    true: {
      onClick: onRemoveClick,
      startIcon: <RemoveIcon color='primary' />,
    },
    false: {
      onClick: onAddClick,
      startIcon: <AddIcon color='primary' />,
    },
  }

  return (
    <ProjectFullExportTomItemWrapper direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
        <ProjectFullExportTomItemButton
          {...buttonPropsBySelectedFlag[`${selected}`]}
          variant='text'
        >
          <ProjectFullExportTomItemPhase>
            <Typography>
              {phase}
            </Typography>
          </ProjectFullExportTomItemPhase>

          <OverflowTextNew variant='subtitle2' display='inline-block !important'>
            {name || ''}
          </OverflowTextNew>
        </ProjectFullExportTomItemButton>

      <Box minWidth='100px' textAlign='end'>
        <Typography
          variant='tooltip'
          textTransform='none'
          color={theme.palette.secondary.dark}
          component='p'
        >
          {status || ''}
        </Typography>
      </Box>
    </ProjectFullExportTomItemWrapper>
  )
}
