import { useEffect, useState, useRef } from "react"
import { getDocument } from "pdfjs-dist"
import { useAppDispatch } from "@store/store"
import { setIsLoadingPageSizes } from "@store/slices/pdfViewer/pdfViewer"

interface UsePdfPageSizesProps {
  file: string
  stampMode: boolean
}

interface PageSizes {
  width: number
  height: number
  pageNumber: number
  rotate: number
}


const usePdfPageSizes = ({ stampMode, file }: UsePdfPageSizesProps) => {
  const dispatch = useAppDispatch()
  const [pageSizes, setPageSizes] = useState<PageSizes[] | null>(null)
  const pdfCache = useRef<{ [key: string]: any }>({})
  const loadingTaskRef = useRef<any>(null)

  useEffect(() => {
    let isCancelled = false
    const loadPdf = async () => {
      if (pdfCache.current[file]) {
        setPageSizes(pdfCache.current[file])
        return
      }

      const loadingTask = getDocument(file)
      loadingTaskRef.current = loadingTask

      try {
        dispatch(setIsLoadingPageSizes(true))
        const pdf = await loadingTask.promise
        if (isCancelled) {
          loadingTask.destroy()
          dispatch(setIsLoadingPageSizes(false))
          return
        }

        const sizes: PageSizes[] = await Promise.all(
          Array.from({ length: pdf.numPages }, async (_, i) => {
            const page = await pdf.getPage(i + 1)
            const rotate = page.rotate
            const isRotated = rotate === 90 || rotate === 270
            const { width, height } = page.getViewport({ scale: 1 })
            return {
              pageNumber: i + 1,
              width: isRotated ? height : width,
              height: isRotated ? width : height,
              rotate
            }
          })
        )

        if (!isCancelled) {
          pdfCache.current[file] = sizes
          setPageSizes(sizes)

        }
      } catch (error) {
        if (!isCancelled) console.log("Ошибка загрузки PDF:", error)
      } finally {
        loadingTaskRef.current = null
        dispatch(setIsLoadingPageSizes(false))
      }
    }

    if (stampMode) {
      loadPdf()
    }

    return () => {
      isCancelled = true
      if (loadingTaskRef.current) {
        loadingTaskRef.current.destroy()
        dispatch(setIsLoadingPageSizes(false))
      }
    }
  }, [file, stampMode])



  return pageSizes
}

export default usePdfPageSizes