import { StampSettings } from "@store/slices/pdfViewer/pdfViewer.types"

interface UseCreateStampProps {
  stampSettings: StampSettings[]
}

const getTextDimensions = (settings: StampSettings) => {
  const positionDimensions = settings.companyVisibility
    ? { x: 150, y: 75 }
    : { x: 150, y: 55 }
  const nameDimensions = settings.signature
    ? { x: 210, y: 95 }
    : { x: 150, y: 95 }
  return { positionDimensions, nameDimensions }
}

export const useCreateStamp = ({ stampSettings }: UseCreateStampProps) => {
  const getStampData = (settings: StampSettings) => {
    const { company, companyVisibility, date, name, nameVisibility, position, positionVisibility, signature, title } = settings
    const { nameDimensions, positionDimensions } = getTextDimensions(settings)
    const titleLines = title.toUpperCase().split('\n')

    const svg = `
  <svg width="300" height="123" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="298" height="121" fill="transparent" stroke="#003690" stroke-width="1" rx="4px" ry="4px"/>

    <text x="150" y="20" font-family="Arial" font-weight="bold" font-size="13" fill="#003690" text-anchor="middle">
      ${titleLines.map((line, index) => `<tspan white-space="wrap" x="150" dy="${index === 0 ? 0 : 18}">${line}</tspan>`).join('')}
    </text>

  ${companyVisibility &&
      `<text x="150" y="55" font-family="Arial" font-weight="bold" font-size="12" fill="#003690" text-anchor="middle">
    ${company}
  </text>`}

  ${positionVisibility &&
      `<text x="${positionDimensions.x}" y="${positionDimensions.y}" font-family="Arial" font-weight="bold" font-size="12" fill="#003690" text-anchor="middle">
    ${position}
  </text>`}

  ${signature &&
      `<line x1="30" y1="95" x2="130" y2="95" stroke="#003690" stroke-width="1" />

  <text x="80" y="105" font-family="Arial" font-size="7" fill="#003690" text-anchor="middle">
    (Подпись)
  </text>`}

  ${nameVisibility &&
      `<text x="${nameDimensions.x}" y="${nameDimensions.y}" font-family="Arial" font-size="12" font-weight="bold" fill="#003690" text-anchor="middle">
    ${name}
  </text>`}

  ${date !== null &&
      `<text x="150" y="115" font-family="Arial" font-size="12" font-weight="bold" fill="#003690" text-anchor="middle">
    ${date}
  </text>`}
</svg>`

    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`
  }

  const stampsData = stampSettings.map((settings) => {
    return {
      data: getStampData(settings),
      name: `Штамп ${settings.title}`,
      type: settings.type
    }
  })

  const stampImage: HTMLImageElement[] = stampsData.map(({ data }) => {
    const img = new Image()
    img.src = data
    return img
  })


  return { stampsData, stampImage }
}