import { Button, Select, styled } from "@mui/material";
import { CustomStampTextField } from "../CustomStampForm";

export const SelectField = styled(Select)`
  height: 32px;
  font-size: 14px;
  border-radius: 6px;

  & .MuiSelect-outlined {
    padding: 5px 8px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.palette.text.dark + '1F'};
    border-width: 1px;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.palette.primary.main};
    border-width: 1px;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${props => props.theme.palette.primary.main};
  }
`

export const PagesField = styled(CustomStampTextField)`
  & .MuiOutlinedInput-root {
    height: 32px !important;
  }
`
export const ApplyStampPosition = styled(Button)`
  font-size: 13px;
`