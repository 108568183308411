import { ChangeEvent, useEffect, useRef, useState } from "react"
import { ApplyStampPosition, AutomaticStampPlacementProps, PagesField, PagesFieldData, SelectedPages, SelectField } from "."
import { FormWrapper } from "../CustomStampForm"
import { Box, MenuItem, Stack, Typography } from "@mui/material"
import { useAppDispatch, useTypedSelector } from "@store/store"
import { documentPagesSelector, isHighlightedSelector, selectAllPagesSelector } from "@store/slices/pdfViewer/selectors/pdfViewer.selectors"
import { setIsSubmitPlacement, setPagesForStamp, setSelectAllPages } from "@store/slices/pdfViewer/pdfViewer"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from "@components/Tooltip"

const defaultFieldValue: PagesFieldData = { value: '', isError: true, message: 'Поле не может быть пустым' }

const parsePageRanges = (value: string, maxLimit: number | null) => {
  const cleanedInput = value.replace(/\s+/g, '')

  if (/[^0-9,\-]/.test(cleanedInput)) {
    return {
      isError: true,
      values: [],
      message: "Строка содержит недопустимые символы",
    }
  }

  const result = new Set<number>()
  const parts = cleanedInput.split(',');

  for (const part of parts) {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number)

      if (start === 0 || end === 0) {
        return {
          isError: true,
          values: [],
          message: "Некорректный формат диапазона",
        }
      }

      if (start > end) {
        return {
          isError: true,
          values: [],
          message: `Диапазон указан некорректно: ${part}`,
        }
      }

      if (maxLimit && (start > maxLimit || end > maxLimit)) {
        return {
          isError: true,
          values: [],
          message: 'Превышено допустимое количество страниц',
        }
      }

      for (let i = start; i <= end; i++) {
        if (i > 0) result.add(i)
      }
    } else {
      const number = Number(part)
      if (isNaN(number)) {
        return {
          isError: true,
          values: [],
          message: `Некорректное число: ${part}`,
        }
      }

      if (maxLimit && number > maxLimit) {
        return {
          isError: true,
          values: [],
          message: 'Превышено допустимое количество страниц',
        }
      }

      if (number > 0) result.add(number)
    }
  }

  return {
    isError: false,
    values: Array.from(result).sort((a, b) => a - b),
    message: ''
  }
}

export const AutomaticStampPlacement = ({ automaticPlacement }: AutomaticStampPlacementProps) => {
  const dispatch = useAppDispatch()
  const selectAllPages = useTypedSelector(selectAllPagesSelector)
  const documentPages = useTypedSelector(documentPagesSelector)
  const isHighlighted = useTypedSelector(isHighlightedSelector)
  const [pagesValue, setPagesValue] = useState<PagesFieldData>(defaultFieldValue)
  const [contentHeight, setContentHeight] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setContentHeight(selectAllPages ? 138 : 202)
  }, [selectAllPages])

  const changePages = (value: string) => {
    if (value === '') {
      setPagesValue({ value, isError: true, message: 'Поле не может быть пустым' })
    } else {
      const { isError, values, message } = parsePageRanges(value, documentPages)
      setPagesValue({ value, isError, message })
      dispatch(setPagesForStamp(values))
    }
  }

  const changeSelectedPages = (value: SelectedPages) => {
    if (value === 'Все' && documentPages) {
      const allPagesForStamp = Array.from({ length: documentPages }, (_, i) => i + 1)
      dispatch(setPagesForStamp(allPagesForStamp))
      dispatch(setSelectAllPages(true))
      setPagesValue(defaultFieldValue)
    } else {
      dispatch(setSelectAllPages(false))
    }
  }

  const handleSubmitPlacement = () => {
    dispatch(setIsSubmitPlacement(true))
  }

  useEffect(() => {
    if (!automaticPlacement) {
      setPagesValue(defaultFieldValue)
    }
  }, [automaticPlacement])

  return (
    <FormWrapper collapse={automaticPlacement} ref={contentRef} height={automaticPlacement ? contentHeight : 0}>
      <Stack direction='column' spacing={1}>
        <Typography fontSize={14}>Страницы</Typography>
        <SelectField
          value={selectAllPages ? 'Все' : 'Некоторые'}
          onChange={(e) => changeSelectedPages(e.target.value as SelectedPages)}>
          <MenuItem value={'Все'}>Все</MenuItem>
          <MenuItem value={'Некоторые'}>Некоторые</MenuItem>
        </SelectField>
        {!selectAllPages &&
          <PagesField
            variant="outlined"
            value={pagesValue.value}
            error={pagesValue.isError}
            helperText={pagesValue.message}
            placeholder='Например 1- 5, 8, 11 - 13'
            onChange={(e: ChangeEvent<HTMLInputElement>) => changePages(e.target.value)}
          />
        }
        <Typography fontSize={14}>Положение штампов</Typography>
        <Tooltip variant='light' title='Применить текущее положение выделенного штампа ко всем штампам на выбранных страницах'>
          <Box>
            <ApplyStampPosition variant='contained' startIcon={<InfoOutlinedIcon fontSize='medium' />}
              disabled={!isHighlighted} onClick={handleSubmitPlacement}>
              Применить ко всем штампам
            </ApplyStampPosition>
          </Box>
        </Tooltip>
      </Stack>
    </FormWrapper>
  )
}