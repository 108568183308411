import { RootState } from "@store/store";
import { QRCode, SelectedStamp, Stamp, StampMode, StampSettings } from "../pdfViewer.types";

export const selectedStampSelector = (state: RootState): SelectedStamp | null => state.pdfViewer.selectedStamp
export const stampSettingsSelector = (state: RootState): StampSettings[] => state.pdfViewer.stampSettings
export const stampsSelector = (state: RootState): Stamp[] => state.pdfViewer.stamps
export const saveTriggerSelector = (state: RootState): boolean => state.pdfViewer.saveTrigger
export const stampModeSelector = (state: RootState): StampMode => state.pdfViewer.stampMode
export const isSelectedQRCodeSelector = (state: RootState): boolean => state.pdfViewer.isSelectedQRCode
export const qrCodeDataSelector = (state: RootState): string | null => state.pdfViewer.qrCodeData
export const qrCodesSelector = (state: RootState): QRCode[] => state.pdfViewer.qrCodes
export const documentPagesSelector = (state: RootState): number | null => state.pdfViewer.documentPages
export const pagesForStampSelector = (state: RootState): number[] => state.pdfViewer.pagesForStamp
export const automaticPlacementSelector = (state: RootState): boolean => state.pdfViewer.automaticPlacement
export const isSubmitPlacementSelector = (state: RootState): boolean => state.pdfViewer.isSubmitPlacement
export const isHighlightedSelector = (state: RootState): boolean => state.pdfViewer.isHighlighted
export const isLoadingPageSizesSelector = (state: RootState): boolean => state.pdfViewer.isLoadingPageSizes
export const selectAllPagesSelector = (state: RootState): boolean => state.pdfViewer.selectAllPages