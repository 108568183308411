import AddIcon from '@mui/icons-material/Add'
import { Stack } from '@mui/material'
import useRouteTracker from 'hooks/useRouteTracker'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Innermap } from '../../api/internalAgreement/types'
import { useGetTomRemarksQuery, useRemarkAccessQuery } from '../../api/remarks'
import CustomEditIcon from '../../assets/icons/CustomEditIcon'
import EmptyPage from '../../components/EmptyPage'
import { ButtonData, EmptyPageData } from '../../components/EmptyPage/EmptyPage.types'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog'
import useQuery from '../../hooks/useQuery'
import { onDrawerClose, openedDrawerSelector, setOpenedDrawer } from '../../store/slices/documentsPages/drawerInfo'
import { selectedMenuViewSelector } from '../../store/slices/documentsPages/menu'
import {
  defaultProjectInfoSelector,
  selectedProjectPhaseSelector,
  setSelectedProjectPhase,
} from '../../store/slices/documentsPages/projectInfo'
import {
  currentDocumentSelector,
  docsSelector,
  isCmnTomSelector,
  tomSelector,
} from '../../store/slices/documentsPages/tom'
import {
  TableFormValues,
  currentSchemaSelector,
  setCurrentSchema,
  setInitialSchemaCompanyFromMenu,
  setIsOpenFormAddMember,
  setSchemaFormMode,
  setTableFormValues,
} from '../../store/slices/internalAgreement'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { ProjectPhaseEn, projectPhaseEnToRu, projectPhaseRuToEn } from '../../types/project'
import { determineTomType, TomDocument } from '../../types/tom'
import { getEmptyPageData } from '../Home'
import { InternalAgreementSchemes } from '../Schemes/InternalAgreementSchemes'
import { AttachmentsExportDrawer } from './components/AttachmentsExportDrawer'
import { AttachmentsPage } from './components/AttachmentsPage'
import { DocumentAccessDrawer } from './components/DocViewNavBarContentInfo/DocumentAccessDrawer'
import { DocViewRightSideBar } from './components/DocViewRightSideBar'
import { FormPocketDrawer } from './components/FormPocketDrawer'
import { InternalAgreementDrawer } from './components/InternalAgreementDrawer'
import { CMN_TOM_TITLE } from './types'
import { Annotation, IncommingAnnotation } from './components/PragmaPdfViewer'
import { PragmaPdfViewer } from './components/PragmaPdfViewer/PragmaPdfViewer'
import { setIsAnnotationMode, setRemarkFormData, setSelectedRemarkId } from '@store/slices/remarks/remarks'
import { RemarkFormData } from '@store/slices/remarks/remarks.types'
import { RemarkFormDrawer } from '@pages/RemarksPage/components/RemarkFormDrawer'
import { selectedRemarkIdSelector } from '@store/slices/remarks/selectors/remarks.selectors'
import { StampRightSideBar } from './components/StampRightSideBar'
import { useReplaceTomRdMutation } from '@api/rdPhase'
import { useReplaceTomPdMutation } from '@api/pdPhase'
import { useReplaceTomIiMutation } from '@api/iiPhase'
import { useReplaceTomIrdMutation } from '@api/irdPhase'
import { qrCodesSelector, selectedStampSelector, stampModeSelector, stampSettingsSelector, stampsSelector } from '@store/slices/pdfViewer/selectors/pdfViewer.selectors'
import { setIsSelectedQRCode, setQRCodes, setSaveTrigger, setSelectedStamp, setStampMode, setStamps } from '@store/slices/pdfViewer/pdfViewer'
import { StampMode } from '@store/slices/pdfViewer/pdfViewer.types'

const getTitleName = (currentDocument: TomDocument) => {
  return currentDocument?.change === null
    ? `Версия ${currentDocument?.version}`
    : `Изм. ${currentDocument?.change}`
}

const DocView: React.FC = () => {
  const dispatch = useAppDispatch()
  const queryHandler = useQuery()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const presetPhase: ProjectPhaseEn | null = queryHandler.get('phase')
  const queryCreateRemark = queryHandler.get('remark')
  const { project, userWithLessControls } = useTypedSelector(defaultProjectInfoSelector)
  const selectedProjectPhase = useTypedSelector(selectedProjectPhaseSelector)
  const { tom } = useTypedSelector(tomSelector)
  const isAnnulled: boolean = !!tom?.features.annulment.annulled
  const { tomVersions, tomChanges } = useTypedSelector(docsSelector)
  const { selectedMenuView } = useTypedSelector(selectedMenuViewSelector)
  const { openedDrawer } = useTypedSelector(openedDrawerSelector)
  const isCmnTom = useTypedSelector(isCmnTomSelector)
  const currentSchema = useTypedSelector(currentSchemaSelector)
  const { currentDocument } = useTypedSelector(currentDocumentSelector)
  const stamps = useTypedSelector(stampsSelector)
  const qrCodes = useTypedSelector(qrCodesSelector)
  const selectedStampMode = useTypedSelector(stampModeSelector)
  const stampSettings = useTypedSelector(stampSettingsSelector)
  const [confirmTrigger, setConfirmTrigger] = useState<'stampCancel' | 'stampSave' | 'agreementDrawerClose' | null>(null)
  const [isOpenRemarkForm, setIsOpenRemarkForm] = useState<boolean>(false)
  const [activeStampMode, setActiveStampMode] = useState(false)


  const selectedRemark = useTypedSelector(selectedRemarkIdSelector)
  const [replaceTomRd] = useReplaceTomRdMutation()
  const [replaceTomPd] = useReplaceTomPdMutation()
  const [replaceTomIi] = useReplaceTomIiMutation()
  const [replaceTomIrd] = useReplaceTomIrdMutation()
  const { data: remarkAcces } = useRemarkAccessQuery(
    {
      projectId,
      tomId: tom?.id!,
      type: determineTomType(selectedProjectPhase),
    },
    { skip: !tom },
  )

  const { data: remarks } = useGetTomRemarksQuery(
    { projectId, tomId: tom?.id!, type: determineTomType(selectedProjectPhase) },
    { skip: !tom },
  )

  const remarkAnnotations = useMemo((): IncommingAnnotation[] => {
    const annotation: IncommingAnnotation[] = []
    const isVersion = currentDocument.version !== null
    const isChange = currentDocument.change !== null
    //remark.change === null доп проверка что бы замечания с версиями не включались 
    remarks?.data.filter(({ remark, messages }) => isChange ? remark.change === currentDocument.change
      : isVersion ? remark.change === null && messages[0].message.version === currentDocument.version : false)
      .forEach(({ remark, messages }) => {
        const page = remark.page
        let marks: Annotation[] = []
        messages.forEach((message) => {
          if (message.marks) { // marks из каждого сообщения
            message.marks.forEach((a) => {
              marks = [...marks, { id: a.id, ...a.annotation }]
            })
          }
        })
        if (page && marks.length > 0) {
          annotation.push({ page: page, remarkId: remark.id, annotations: marks })
        }
      })
    return annotation
  }, [currentDocument.change, currentDocument.version, remarks?.data])

  const pageOfSelectedRemark = useMemo(() => {
    if (!selectedRemark || !remarks) return null
    const selected = remarks.data.find(({ remark }) => remark.id === selectedRemark)
    if (selected && selected.remark.page) {
      return {
        page: selected.remark.page,
        selectedId: selectedRemark
      }
    } else return null
  }, [remarks, selectedRemark])

  // Режим штампов
  const handleCancel = (options: { closeStampMode?: boolean }) => {
    if (stamps.length > 0 || qrCodes.length > 0) {
      setConfirmTrigger('stampCancel')
      openConfirm({ closeStampMode: options.closeStampMode })
    } else {
      if (selectedStampMode === 'stamp') {
        dispatch(setSelectedStamp(null))
        if (stampSettings.length > 1) {
          setActiveStampMode(false)
        }
      } else {
        setActiveStampMode(false)
        dispatch(setIsSelectedQRCode(false))
      }
    }
  }

  const handleChangeStampMode = (value: StampMode) => {
    if (value === null) return
    if (stamps.length > 0 || qrCodes.length > 0) {
      setConfirmTrigger('stampCancel')
      openConfirm({ closeStampMode: false, changeStampMode: value })
    } else {
      dispatch(setSelectedStamp(null))
      dispatch(setIsSelectedQRCode(false))
      dispatch(setStampMode(value))
    }
  }

  const handleSave = () => {
    setConfirmTrigger('stampSave')
    openConfirm()
  }

  const changeStampMode = ({ immediatelyClose }: { immediatelyClose?: boolean }) => {
    if (immediatelyClose) {
      setActiveStampMode(false)
      return
    }
    if (activeStampMode) {
      if (stamps.length > 0 || qrCodes.length > 0) {
        handleCancel({ closeStampMode: true })
      } else {
        dispatch(setSelectedStamp(null))
        dispatch(setIsSelectedQRCode(false))
        dispatch(setQRCodes([]))
        dispatch(setStampMode('stamp'))
        setActiveStampMode(false)
      }
    } else setActiveStampMode(true)
  }

  const replaceFile = (file: File) => {
    switch (selectedProjectPhase) {
      case 'Рабочая документация':
        replaceTomRd({
          docId: currentDocument.docId,
          file,
          type: 'РД'
        })
        break
      case 'Проектная документация':
        replaceTomPd({
          docId: currentDocument.docId,
          file,
          type: 'ПД'
        })
        break
      case 'Инженерные изыскания':
        replaceTomIi({
          docId: currentDocument.docId,
          file,
          type: 'ИИ'
        })
        break
      case 'Сбор исходных данных':
        replaceTomIrd({
          docId: currentDocument.docId,
          file,
          type: 'ИРД'
        })
    }
  }

  const pdfLink =
    tomVersions?.length || tomChanges?.length
      ? !tomVersions?.length
        ? tomChanges[0]?.doc?.link
        : tomVersions[0]?.link
      : ''

  useRouteTracker({ record: true })

  useBreadcrumbs(
    [{ title: project?.shifrName!, url: './toms' }, isCmnTom ? { title: CMN_TOM_TITLE } : { title: tom?.title! }],
    [project, tom, isCmnTom, currentSchema],
  )

  const getEmptyPageDataButtons = (): ButtonData[] => {
    if (isAnnulled) return []
    // @ts-ignore
    const data: ButtonData[] = [
      tom?.status === 'Согласовано' &&
      tom.features.changeMode && {
        text: 'Внести изменения',
        icon: CustomEditIcon,
        onClick: () => dispatch(setOpenedDrawer({ openedDrawer: 'docChange' })),
      },
      tom?.status !== 'Согласовано' && {
        text: 'Добавить версию',
        icon: AddIcon,
        onClick: () => dispatch(setOpenedDrawer({ openedDrawer: 'version' })),
      },
    ].filter((v) => !!v)

    return data
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>
      Для тома не было загружено ни одной версии документа. <br />
      Нажмите добавить версию для загрузки файла.
    </>,
    getEmptyPageDataButtons(),
  )

  const emptyPageDataWithoutControls: EmptyPageData = getEmptyPageData(
    <>Для тома не было загружено ни одной версии документа.</>,
  )

  const displayCreateRemarkButton = useMemo(() => {
    const access = remarkAcces?.access
    const isLatestVersion = currentDocument.version === null
      ? true
      : tomVersions.length > 0
        ? currentDocument.version === tomVersions[0].version
        : false

    return !isAnnulled && isLatestVersion && access
  }, [currentDocument.version, isAnnulled, remarkAcces?.access, tomVersions])

  const createRemark = (page: number, numPages: number | null) => {
    dispatch(setIsAnnotationMode(true))
    dispatch(setSelectedRemarkId(null))
    setIsOpenRemarkForm(true)
    dispatch(
      setRemarkFormData({
        remarkFormData: {
          page,
          totalPages: numPages,
          version: currentDocument.version,
          change: currentDocument.change,
        } as RemarkFormData,
      }),
    )
  }

  const getContent = () => {
    switch (selectedMenuView) {
      case 'info':
      case 'versions':
      case 'changes':
      case 'remarks':
        return (
          <Stack direction='row' spacing={2.5} style={{ overflow: 'hidden' }} flex={1} mt={1}>
            {pdfLink && Object.keys(currentDocument).length ? (
              <PragmaPdfViewer
                file={currentDocument.link}
                isOpenRemarkForm={isOpenRemarkForm}
                actionButtonRemark={displayCreateRemarkButton ? createRemark : undefined}
                remarkAnnotations={remarkAnnotations}
                pageOfSelectedRemark={pageOfSelectedRemark}
                fileName={getTitleName(currentDocument)}
                stampMode={activeStampMode}
                changeStampMode={changeStampMode}
                replaceFile={replaceFile} />
            ) : (
              <EmptyPage
                data={userWithLessControls ? emptyPageDataWithoutControls : emptyPageData}
                fullPage
                wrapperProps={{ style: { marginTop: 0 } }}
              />
            )}
            {activeStampMode
              ? <StampRightSideBar
                docId={currentDocument.docId}
                type={determineTomType(selectedProjectPhase)}
                handleCancel={handleCancel}
                handleSave={handleSave}
                handleChangeStampMode={handleChangeStampMode}
              />
              : <DocViewRightSideBar
                isOpenRemarkForm={isOpenRemarkForm}
                hasNoDocument={!pdfLink}
                tomVersions={tomVersions}
                tomChanges={tomChanges}
                currentDocument={currentDocument}
                tomId={tom?.id}
                type={determineTomType(selectedProjectPhase)}
                closeRemarkForm={() => setIsOpenRemarkForm(false)}
                openRemarkForm={() => setIsOpenRemarkForm(true)}
              />
            }
          </Stack>
        )

      case 'attachments':
        return (
          <>
            <AttachmentsPage />
            <AttachmentsExportDrawer />
          </>
        )
    }
  }

  const closeDrawer = (dirty: boolean) => {
    if (dirty) {
      openConfirm()
    } else onInternalAgreementDrawerClose(true)
  }

  const onInternalAgreementDrawerClose = (confirm: boolean) => {
    if (confirm) {
      dispatch(setCurrentSchema({} as Innermap))
      dispatch(setSchemaFormMode({ schemaFormMode: 'view' }))
      dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
      dispatch(setTableFormValues({ tableFormValues: {} as TableFormValues }))
      dispatch(setInitialSchemaCompanyFromMenu({ initialSchemaCompanyFromMenu: '' }))
      dispatch(onDrawerClose({ dirty: false }))
    }
  }

  const handleCancelConfirm = (confirm: boolean, options: { closeStampMode?: boolean, changeStampMode?: StampMode }) => {
    if (confirm) {
      if (selectedStampMode === 'stamp') {
        dispatch(setSelectedStamp(null))
        dispatch(setStamps([]))
        options.closeStampMode && setActiveStampMode(false)
        options.changeStampMode && dispatch(setStampMode(options.changeStampMode))
      } else {
        dispatch(setIsSelectedQRCode(false))
        dispatch(setQRCodes([]))
        dispatch(setStampMode('stamp'))
        !options.changeStampMode && setActiveStampMode(false)
      }

    }
  }

  const handleSaveConfirm = (confirm: boolean) => {
    if (confirm) {
      dispatch(setSaveTrigger(true))
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps =
    confirmTrigger === 'stampCancel'
      ? {
        handleConfirm: handleCancelConfirm,
        title: <>Подтвердить закрытие режима работы <br /> {selectedStampMode === 'stamp' ? 'со штампами?' : 'c QR-кодами'}</>,
        body: 'Все внесенные изменения будут безвозвратно утеряны.'
      } : confirmTrigger === 'stampSave'
        ? {
          handleConfirm: handleSaveConfirm,
          title: 'Подтвердить сохранение изменений?',
          body: selectedStampMode === 'stamp'
            ? 'Штампы будут установлены на документ. Редактирование установленных штампов будет недоступно.'
            : 'QR-коды будут установлены на документ. Редактирование установленных QR-кодов будет недоступно.'
        } : {
          handleConfirm: onInternalAgreementDrawerClose,
          denyButtonText: 'Отменить',
        }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const immediatelyDrawerClose = (dirty: boolean, immediately?: boolean) => {
    dispatch(onDrawerClose({ dirty, immediately }))
  }

  useEffect(() => {
    if (presetPhase) {
      const queryPhase = projectPhaseEnToRu[presetPhase]
      if (queryPhase !== selectedProjectPhase) {
        dispatch(setSelectedProjectPhase({ selectedProjectPhase: projectPhaseEnToRu[presetPhase] }))
      }
    } else {
      queryHandler.set('phase', projectPhaseRuToEn[selectedProjectPhase])
    }
  }, [queryHandler])

  // Добавление замечаний через редирект со страницы схемы согласования
  useEffect(() => {
    if (queryCreateRemark === 'create' && displayCreateRemarkButton) {
      createRemark(1, null)
    }
  }, [displayCreateRemarkButton])

  return (
    <>
      {currentSchema?.id && selectedMenuView === 'remarks' ? (
        <>
          <InternalAgreementSchemes />
          <RemarkFormDrawer
            tomId={tom?.id}
            type={determineTomType(selectedProjectPhase)}
          />
        </>
      ) : (
        <>
          {getContent()}
        </>
      )}

      <FormPocketDrawer
        open={openedDrawer === 'formPocket'}
        onDrawerClose={immediatelyDrawerClose}
        initialPhase={selectedProjectPhase}
      />
      <InternalAgreementDrawer
        open={openedDrawer === 'internalAgreement'}
        closeDrawer={closeDrawer}
        tomId={tom?.id}
        type={determineTomType(selectedProjectPhase)}
      />
      <DocumentAccessDrawer
        open={openedDrawer === 'docAccess'}
        closeDrawer={immediatelyDrawerClose}
        tomId={tom?.id}
        type={determineTomType(selectedProjectPhase)}
      />
      <ConfirmDialog />
    </>
  )
}

export default DocView
